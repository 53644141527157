import { createStore } from "vuex"
import auth, { AuthState } from "./modules/auth"
import app, { AppState } from "./modules/app"
import object, { ObjectState } from "./modules/object"
import company, { CompanyState } from "./modules/company"
import customer, { CustomerState } from "./modules/customer"
import financer, { FinancerStore } from "./modules/financer"
import affiliateLinkOld, { AffiliateLinkOldState } from "./modules/affiliate-link-old"
import publicUser, { PublicUserState } from "./modules/publicUser"
import user, { UserState } from "./modules/user"
import employees, { EmployeeState } from "./modules/employee"
import categoryTemplates, { CategoryTemplateState } from "./modules/categoryTemplate"
import customerPortalData, { CustomerPortalDataState } from "./modules/customerPortalData"
import products, { ProductsState } from "./modules/products"
import campaigns, { CampaignsState } from "./modules/campaigns"
import affiliates, { AffiliatesState } from "./modules/affiliates"
import affiliateRoutes, { AffiliateRoutesState } from "./modules/affiliateRoutes"
import affiliateLinks, { AffiliateLinksState } from "./modules/affiliateLink"

export interface StoreState {
    auth?: AuthState
    app?: AppState
    object?: ObjectState
    company?: CompanyState
    customer?: CustomerState
    financer?: FinancerStore
    affiliateLinkOld? : AffiliateLinkOldState

    publicUser?: PublicUserState
    user?: UserState
    employees?: EmployeeState
    categoryTemplates?: CategoryTemplateState
    customerPortalData?: CustomerPortalDataState
    products?: ProductsState
    campaigns?: CampaignsState
    affiliates?: AffiliatesState
    affiliateRoutes?: AffiliateRoutesState
    affiliateLinks?: AffiliateLinksState

    isLoading: boolean
}

export default createStore<StoreState>({
    state: {
        isLoading: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        app,
        object,
        company,
        customer,
        financer,
        affiliateLinkOld,
        publicUser,
        user,
        employees,
        categoryTemplates,
        customerPortalData,
        products,
        campaigns,
        affiliates,
        affiliateRoutes,
        affiliateLinks

    }
})