import { firestore } from "@/firebase"
import { collection, doc } from "firebase/firestore"
import { FirestoreClass } from "./FirestoreClass"

export enum  LogType {
    docChanged  = "docChanged",
    docCreated = "docCreated",
    docDeleted = "docDeleted",
    epCaseCreated = "epCaseCreated",
    epCaseUpdated = "epCaseUpdated",
    forumCaseCreated = "forumCaseCreated",
    forumCaseUpdated = "forumCaseUpdated",
    baufinexCaseCreated = "baufinexCaseCreated",
    baufinexCaseUpdated = "baufinexCaseUpdated",
    resendEmail = "resendEmail",
    sendEmail = "sendEmail"
}


export enum Frontend {
    financer = "financer",
    dispatcher = "dispatcher",
    kundenportal = "kundenportal"
}

export class Log extends FirestoreClass {
    frontend: Frontend = Frontend.financer
    username: string = ""
    userid: string = ""
    companyid: string = ""
    docRefPath: string = ""
    newData: object = {}
    oldData: object = {}
    timestamp: Date = new Date()
    logType: LogType = LogType.docChanged
    
    constructor(id: string = "") {
        super(id)
        this.timestamp = new Date()
    }

    toData() {
        return {
            frontend: this.frontend,
            username: this.username,
            userid: this.userid,
            companyid: this.companyid,
            docRefPath: this.docRefPath,
            newData: this.newData,
            oldData: this.oldData,
            timestamp: this.timestamp,
            logType: this.logType
        }
    }

    get ref() {
        return doc(this.collectionRef, this.id)
    }
    get collectionRef() {
        return collection(firestore, "Log")
    }

}