const algorithm = "aes-256-ctr"
const secretKey = "vOLH6sd3pNWj4RIqCf7rdYs01lGHzfr3"

export class Hash {
    iv: string = ""
    content: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        this.iv = data?.iv as string || ""
        this.content = data?.content as string || ""
    }

    toData() {
        const data: Record<string, unknown> = {
            iv: this.iv as string || "",
            content: this.content as string || ""
        }
        return data
    }
}