import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import { Employee } from "@/classes/Employee"
import { AffiliateLink } from "@/classes/AffiliateLink"
import store from ".."

export interface AffiliateLinksState {
    affiliateLinks: Map<string, AffiliateLink[]>,
    firestoreListener: {
        affiliateLinks: Unsubscribe | null
    }
}

export interface getEmployeeAffiliateLinksPayload {
    employee: Employee,
    loadAffiliate: boolean
}

const module: Module<AffiliateLinksState, unknown> = {
    state: {
        affiliateLinks: new Map<string, AffiliateLink[]>(),
        firestoreListener: {
            affiliateLinks: null
        }
    },
    actions: {
        getEmployeeAffiliateLinks({ state }, payload: getEmployeeAffiliateLinksPayload) {
            const createAffiliateLink = async (employee: Employee, name: string) => {
                const affiliateLink = new AffiliateLink(employee)
                affiliateLink.fromData({
                    name: name,
                    isDefault: true,
                    affiliateRouteId: "standard_baufinanzierung"
                })

                await affiliateLink.set()
                return affiliateLink
            }

            const createDefaultAffiliateLinks = async (employee: Employee) => {
                await createAffiliateLink(employee, "Baufinanzierung ohne Zuträger")
            }

            return new Promise(async (resolve, reject) => {

                if (payload.loadAffiliate) {
                    await store.dispatch("getEmployeeAffiliates", payload.employee)
                }

                if (state.affiliateLinks?.get(payload.employee.id)) {
                    resolve(state.affiliateLinks?.get(payload.employee.id))
                    return
                }


                const affiliateLink = new AffiliateLink(payload.employee)
                onSnapshot(affiliateLink.collectionRef, async (snapshot) => {
                    const _affiliateLinks: AffiliateLink[] = []
                    for (const doc of snapshot.docs) {
                        const obj = new AffiliateLink(payload.employee, doc.id)
                        obj.fromData(doc.data())
                        if (payload.loadAffiliate) {
                            obj.affiliate = store.state.affiliates?.affiliates.get(payload.employee.id)?.find(a => a.id === obj.affiliateId)
                        }
                        _affiliateLinks.push(obj)
                    }

                    if (_affiliateLinks.length === 0) {
                        createDefaultAffiliateLinks(payload.employee)
                    }

                    state.affiliateLinks?.set(payload.employee.id, _affiliateLinks)
                    resolve(state.affiliateLinks?.get(payload.employee.id))
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }

    }
}
export default module