import { CustomApi, CustomApiState } from "./CustomApi"

export class EuropaceApi extends CustomApi {
    override get state() {
        if (!this.useApi) {
            return {
                msg: "Inaktiv",
                state: CustomApiState.Inactive,
                classes: ["text-black"]
            }
        }

        if (!this.code || !this.idToken || !this.accessToken || !this.partnerId) {
            return {
                msg: "Authentifizierung ausstehend",
                state: CustomApiState.WaitingForAuth,
                classes: ["text-yellow-500"]
            }
        }

        return {
            msg: "Aktiv",
            state: CustomApiState.Active,
            classes: ["text-success"]
        }
    }
}
