
import { computed } from "@vue/reactivity"
import { defineComponent, ref, watch } from "vue"

export default defineComponent({
    props: {
        duration: {
            type: Number,
            default: 5000
        },
        color: {
            type: String,
            default: "default"
        },
        modelValue: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const show = ref(false)
        const classList = computed(() => {
            const list: string[] = ["p-2 rounded-md", "text-white", "shadow-lg", "sm:p-3"]
            switch (props.color) {
                case "success":
                    list.push("bg-success")
                    break
                case "error":
                    list.push("bg-error")
                    break
                default:
                    list.push("bg-snackbar")
                    break
            }

            return list
        })

        watch(() => props.modelValue, (val) => {
            show.value = val
            if (val) {
                setTimeout(() => {
                    show.value = false
                }, props.duration)
            }
        })

        watch(show, (val) => {
            emit("update:modelValue", val)
        })

        return {
            show,
            classList
        }
    }
})
