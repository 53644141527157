import { collection, doc, Timestamp } from "firebase/firestore"
import { Company } from "./Company"
import { Customer } from "./Customer"
import { FirestoreClass } from "./FirestoreClass"

type ObjectEstateState = "pending" | "financer_reply" | "redirected"
export class Address {
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    floor: string = ""
    room: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.street = data.street as string || ""
            this.number = data.number as string || ""
            this.postcode = data.postcode as string || ""
            this.location = data.location as string || ""
            this.floor = data.floor as string || ""
            this.room = data.room as string || ""
        }
    }
    toData() {
        return {
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            floor: this.floor,
            room: this.room
        }
    }
}
export class ObjectEstateInterested {
    id: string = ""
    timestamp: Date = new Date()
    rating: number = 0
    note: string | null = null
    flags: string[] = []

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.id = data.id as string
            this.timestamp = (data.timestamp as Timestamp).toDate()
            this.rating = data.rating as number || 0
            this.note = data.note as string || null
            this.flags = data.flags as string[] || []
        }
    }
    addFlag(flag: string) {
        if (!this.hasFlag(flag)) {
            this.flags.push(flag)
        }
    }
    hasFlag(flag: string) {
        return this.flags.indexOf(flag) != -1
    }
    deleteFlag(flag: string) {
        if (this.hasFlag(flag)) {
            this.flags.splice(this.flags.indexOf(flag), 1)
        }
    }

    toData() {
        return {
            id: this.id,
            timestamp: this.timestamp,
            rating: this.rating,
            note: this.note,
            flags: this.flags
        }
    }
}
export class ObjectEstate extends FirestoreClass {
    private _company: Company
    address: Address = new Address()
    interested: ObjectEstateInterested[] = []
    agentCompany: string = ""
    agent: string = ""
    agentObjectEstateID: string = ""
    dispatcher: string = ""
    financerCompany: string = ""
    financer: string = ""
    state: ObjectEstateState = "pending"
    redirected: string[] = []
    denied: string[] = []

    constructor(company: Company, id: string = "") {
        super(id)
        this._company = company
    }

    addInterested(interested: Customer) {
        const objectInterested = new ObjectEstateInterested()
        objectInterested.id = interested.id
        objectInterested.timestamp = new Date()
        for (const i of this.interested) {
            if (i.id == objectInterested.id) {
                return
            }
        }
        this.interested.push(objectInterested)
    }
    denyInterested(interested: Customer) {
        if (this.denied.indexOf(interested.id) == -1) {
            this.denied.push(interested.id)
        }
        if (this.redirected.indexOf(interested.id) != -1) {
            this.redirected.splice(this.redirected.indexOf(interested.id), 1)
        }
    }

    override toData() {
        const data: Record<string, Partial<unknown>> = {}
        data.address = this.address.toData()
        data.financerCompany = this.financerCompany
        data.financer = this.financer
        data.dispatcher = this.dispatcher
        data.agentCompany = this.agentCompany
        data.agent = this.agent
        data.agentObjectEstateID = this.agentObjectEstateID
        data.state = this.state
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _i: any[] = []
        for (const interested of this.interested) {
            _i.push(interested.toData())
        }
        data.denied = this.denied
        data.redirected = this.redirected
        data.interested = _i
        data._searchIndex = [
            this.address.street.toUpperCase(),
            this.address.postcode.toUpperCase(),
            this.address.location.toUpperCase()
        ]
        return data
    }
    override fromData(data: Record<string, unknown>): void {
        if (data.address) {
            this.address = new Address(data.address as Record<string, unknown>)
        }
        if (data.interested) {
            for (const interested of data.interested as Record<string, unknown>[]) {
                this.interested.push(new ObjectEstateInterested(interested))
            }
        }
        if (data.dispatcher) {
            this.dispatcher = data.dispatcher as string
        }
        if (data.agentCompany) {
            this.agentCompany = data.agentCompany as string
        }
        if (data.agent) {
            this.agent = data.agent as string
        }
        if (data.agentObjectEstateID) {
            this.agentObjectEstateID = data.agentObjectEstateID as string
        }
        if (data.financerCompany) {
            this.financerCompany = data.financerCompany as string
        }
        if (data.financer) {
            this.financer = data.financer as string
        }
        if (data.denied) {
            this.denied = data.denied as string[]
        }
        if (data.redirected) {
            this.redirected = data.redirected as string[]
        }
        this.state = data.state as ObjectEstateState || "financer_reply"
    }

    override get ref() {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef() {
        return collection(this._company.ref, "object")
    }
}