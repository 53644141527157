import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import { Product, ProductField, ProductFieldType } from "@/classes/Product"
import { Employee } from "@/classes/Employee"

export interface ProductsState {
    products: Map<string, Product[]>,
    firestoreListener: {
        products: Unsubscribe | null
    }
}

const module: Module<ProductsState, unknown> = {
    state: {
        products: new Map<string, Product[]>(),
        firestoreListener: {
            products: null
        }
    },
    actions: {
        getEmployeeProducts({ state }, employee: Employee) {

            const createProduct = async (employee: Employee, id: string, name: string, category: string, subCategory: string, fields: ProductField[]) => {
                const product = new Product(employee, id)
                product.fromData({
                    name: name,
                    category: category,
                    subCategory: subCategory,
                    isDefault: true,
                    fields: fields
                })
                
                await product.set()
                return product
            }

            const createDefaultProducts = async (employee: Employee) => {
                const baufiFields: ProductField[] = [
                    new ProductField({
                        name: "Vertragsbeginn",
                        title: "Vertragsbeginn",
                        type: ProductFieldType.date,
                        order: "1",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Zinsbindungsende",
                        title: "Zinsbindungsende",
                        type: ProductFieldType.date,
                        order: "2",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Zins",
                        title: "Zins",
                        type: ProductFieldType.percent,
                        order: "3",
                        showInOverview: "1",
                        uniqueId: "0ec2cc15-4371-46de-b3e6-ea06ad17b024"
                    }),
                    new ProductField({
                        name: "Summe",
                        title: "Summe",
                        type: ProductFieldType.euro,
                        order: "4",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Rate",
                        title: "Rate",
                        type: ProductFieldType.euro,
                        order: "5",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Gesellschaft",
                        title: "Gesellschaft",
                        type: ProductFieldType.text,
                        order: "6",
                        showInOverview: "1"
                    })
                ]

                const bausparenFields: ProductField[] = [
                    new ProductField({
                        name: "Vertragsbeginn",
                        title: "Vertragsbeginn",
                        type: ProductFieldType.date,
                        order: "1",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Zuteilung",
                        title: "Zuteilung",
                        type: ProductFieldType.date,
                        order: "2",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Rate",
                        title: "Rate",
                        type: ProductFieldType.euro,
                        order: "3",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Zins",
                        title: "Zins",
                        type: ProductFieldType.percent,
                        order: "4",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Bausparsumme",
                        title: "Bausparsumme",
                        type: ProductFieldType.euro,
                        order: "5",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Gesellschaft",
                        title: "Gesellschaft",
                        type: ProductFieldType.text,
                        order: "6",
                        showInOverview: "1"
                    })
                ]

                const pkFields: ProductField[] = [
                    new ProductField({
                        name: "Vertragsbeginn",
                        title: "Vertragsbeginn",
                        type: ProductFieldType.date,
                        order: "1",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Summe",
                        title: "Summe",
                        type: ProductFieldType.euro,
                        order: "2",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Zins",
                        title: "Zins",
                        type: ProductFieldType.percent,
                        order: "3",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Rate",
                        title: "Rate",
                        type: ProductFieldType.euro,
                        order: "4",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Laufzeit (Monate)",
                        title: "Laufzeit (Monate)",
                        type: ProductFieldType.number,
                        order: "5",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Gesellschaft",
                        title: "Gesellschaft",
                        type: ProductFieldType.text,
                        order: "6",
                        showInOverview: "1"
                    })
                ]

                const vundvFields: ProductField[] = [
                    new ProductField({
                        name: "Beitrag",
                        title: "Beitrag",
                        type: ProductFieldType.euro,
                        order: "1",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Vertragsbeginn",
                        title: "Vertragsbeginn",
                        type: ProductFieldType.date,
                        order: "2",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Vertragsende",
                        title: "Vertragsende",
                        type: ProductFieldType.date,
                        order: "3",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Gesellschaft",
                        title: "Gesellschaft",
                        type: ProductFieldType.text,
                        order: "4",
                        showInOverview: "1"
                    }),
                    new ProductField({
                        name: "Vertragsnummer",
                        title: "Vertragsnummer",
                        type: ProductFieldType.text,
                        order: "5",
                        showInOverview: "0"
                    }),
                    new ProductField({
                        name: "Zahlweise",
                        title: "Zahlweise (monat, quartal, jahr)",
                        type: ProductFieldType.text,
                        order: "6",
                        showInOverview: "0"
                    })
                ]

                await createProduct(employee, "standard_baufi_fh", "Baufinanzierung Festhypothek", "Baufi", "FH", baufiFields)
                await createProduct(employee, "standard_baufi_ad", "Baufinanzierung Annuitätendarlehen", "Baufi", "AD", baufiFields)
                
                await createProduct(employee, "standard_bausparen_zediert", "Bausparen Zediert", "Bausparen", "Zediert", bausparenFields)
                await createProduct(employee, "standard_bausparen_frei", "Bausparen Frei", "Bausparen", "Frei", bausparenFields)
                
                await createProduct(employee, "standard_versicherungen_pk", "Privatkredit", "Privatkredit", "Privatkredit", pkFields)
                
                await createProduct(employee, "standard_vundv_rlv",        "V+V RLV",          "V+V", "RLV", vundvFields)
                await createProduct(employee, "standard_vundv_hausrat",    "V+V Hausrat",      "V+V", "Hausrat", vundvFields)
                await createProduct(employee, "standard_vundv_wg",         "V+V Wohngebäude",  "V+V", "Wohngebäude", vundvFields)
                await createProduct(employee, "standard_vundv_rente",      "V+V Rente",        "V+V", "Rente", vundvFields)
            }

            return new Promise((resolve, reject) => {
                if (state.products?.get(employee.id)) {
                    resolve(state.products?.get(employee.id))
                    return
                }

                const product = new Product(employee)
                onSnapshot(product.collectionRef, async (snapshot) => {

                    const _products: Product[] = []
                    for (const doc of snapshot.docs) {
                        const obj = new Product(employee, doc.id)
                        obj.fromData(doc.data())
                        _products.push(obj)
                    }

                    if (_products.length === 0) {
                        createDefaultProducts(employee)
                    }

                    state.products?.set(employee.id, _products)

                    resolve(state.products?.get(employee.id))
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }

    }
}
export default module