import { collection, doc } from "firebase/firestore"
import { Employee } from "./Employee"
import { FirestoreClass } from "./FirestoreClass"
import { uuid } from "vue-uuid"

export enum AffiliateRoutePageElementType {
    button = "button",
    textfield = "textfield",
    numberfield = "numberfield",
    datefield = "datefield",
    euro = "euro",
    percent = "percent"
}

export class AffiliateRoutePageElement {
    name: string = ""
    title: string = ""
    type: AffiliateRoutePageElementType = AffiliateRoutePageElementType.button
    uniqueId: string
    order: number = 0

    constructor(data: Record<string, unknown> | null = null) {
        this.name = data?.name as string ?? ""
        this.title = data?.title as string ?? ""
        this.type = data?.type as AffiliateRoutePageElementType ?? AffiliateRoutePageElementType.button
        this.uniqueId = data?.uniqueId as string ?? uuid.v4()
        this.order = data?.order as number ?? 0
        // this.value = data?.value as string ?? ""
    }

    toData() {
        const data: Record<string, unknown> = {
            name: this.name,
            title: this.title,
            type: this.type,
            uniqueId: this.uniqueId,
            order: this.order
        }
        return data
    }

}

export enum AffiliateRoutePageType {
    formular = "formular",
    yesno = "yesno",
    buttons = "buttons"
}

export enum AffiliateRoutePageConditionOperator {
    isEqual = "==",
    isGreaterThan = ">",
    isGreaterOrEqualThan = ">=",
    isLowerThan = "<",
    isLowerOrEqualThan = "<="
}

export class AffiliateRoutePageCondition {
    pageId: string
    elementId: string
    value: string
    uniqueId: string
    operator: AffiliateRoutePageConditionOperator = AffiliateRoutePageConditionOperator.isEqual
    order: number = 0


    constructor(data: Record<string, unknown> | null = null) {
        this.pageId = data?.pageId as string
        this.elementId = data?.elementId as string
        this.value = data?.value as string ?? ""
        this.uniqueId = data?.uniqueId as string ?? uuid.v4()
        this.order = data?.order as number ?? 0
        this.operator = data?.operator as AffiliateRoutePageConditionOperator ?? AffiliateRoutePageConditionOperator.isEqual

    }


    toData() {
        const data: Record<string, unknown> = {
            pageId: this.pageId ?? "",
            elementId: this.elementId ?? "",
            value: this.value ?? "",
            uniqueId: this.uniqueId,
            order: this.order,
            operator: this.operator
        }
        return data
    }
}

export class AffiliateRoutePage {
    title: string = ""
    subTitle: string = ""
    name: string = ""
    type: AffiliateRoutePageType = AffiliateRoutePageType.formular
    elements: AffiliateRoutePageElement[] = []
    conditions: AffiliateRoutePageCondition[] = []
    hasCondition: boolean = false
    elapsed: boolean = false
    uniqueId: string
    order: number = 0

    constructor(data: Record<string, unknown> | null = null) {

        this.title = data?.title as string ?? ""
        this.subTitle = data?.subTitle as string ?? ""
        this.name = data?.name as string ?? ""
        this.type = data?.type as AffiliateRoutePageType ?? AffiliateRoutePageType.formular
        this.conditions = data?.conditions as AffiliateRoutePageCondition[] ?? []
        this.hasCondition = data?.hasCondition as boolean ?? false
        this.uniqueId = data?.uniqueId as string ?? uuid.v4()
        this.order = data?.order as number ?? 0

        if (data?.conditions) {
            this.conditions = []
            for (const _data of data.conditions as (Record<string, unknown> | null)[]) {
                const tempField = new AffiliateRoutePageCondition(_data)
                this.conditions.push(tempField)
            }
        }

        if (data?.elements) {
            this.elements = []
            for (const _data of data.elements as (Record<string, unknown> | null)[]) {
                const tempField = new AffiliateRoutePageElement(_data)
                this.elements.push(tempField)
            }
        }

    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title,
            subTitle: this.subTitle,
            name: this.name,
            type: this.type,
            hasCondition: this.hasCondition,
            uniqueId: this.uniqueId,
            order: this.order
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const conditions: any[] = []
        for (const condition of this.conditions) {
            conditions.push(condition.toData())
        }
        data.conditions = conditions

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const elements: any[] = []
        for (const element of this.elements) {
            elements.push(element.toData())
        }
        data.elements = elements

        return data
    }
}


export class AffiliateRoute extends FirestoreClass {
    private _employee: Employee
    name: string = ""
    duration: string = ""
    isDefault: boolean = false
    pages: AffiliateRoutePage[] = []

    constructor(_employee: Employee, id: string = "") {
        super(id)
        this._employee = _employee
    }

    get employee() {
        return this._employee
    }

    override fromData(data: Record<string, unknown>): void {
        if (data) {
            this.name = data.name as string ?? ""
            this.duration = data.duration as string ?? ""
            this.isDefault = data.isDefault as boolean ?? false

            if (data.pages) {
                this.pages = []
                for (const _data of data.pages as (Record<string, unknown> | null)[]) {
                    const tempField = new AffiliateRoutePage(_data)
                    this.pages.push(tempField)
                }
            }
        }


    }
    override toData() {
        const data: Record<string, unknown> = {}
        data.name = this.name
        data.duration = this.duration
        data.isDefault = this.isDefault

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pages: any[] = []
        for (const page of this.pages) {
            pages.push(page.toData())
        }
        data.pages = pages

        return data
    }

    get ref() {
        return doc(this.collectionRef, this.id)
    }

    get collectionRef() {
        return collection(this._employee.ref, "affiliateRoutes")
    }
}
