import { storage } from "@/firebase"
import { collection, doc, Timestamp } from "firebase/firestore"
import { Employee } from "./Employee"
import { FirestoreClass } from "./FirestoreClass"
import { ref as storage_ref, uploadBytes, getDownloadURL } from "@firebase/storage"


export class Affiliate extends FirestoreClass {
    private _employee: Employee
    firstName: string = ""
    lastName: string = ""
    phone: string = ""
    mobile: string = ""
    email: string = ""
    companyName: string = ""
    notes: string = ""
    birthdate: Date | undefined = undefined
    hasLogo: boolean = false
    _logo: string = ""
    lastUpdate: Date = new Date()

    constructor(_employee: Employee, id: string = "") {
        super(id)
        this._employee = _employee
    }

    get employee() {
        return this._employee
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`.trim()
    }
    
    get fullNameAndCompany() {
        let ret = `${this.firstName} ${this.lastName}`.trim()
        if(this.companyName) {
            ret += ` (${this.companyName})`
        }

        return ret
    }

    override async fromData(data: Record<string, unknown>) {
        if (data) {
            this.firstName = data.firstName as string ?? ""
            this.lastName = data.lastName as string ?? ""
            this.phone = data.phone as string ?? ""
            this.mobile = data.mobile as string ?? ""
            this.email = data.email as string ?? ""
            this.companyName = data.companyName as string ?? ""
            this.notes = data.notes as string ?? ""
            this.hasLogo = data.hasLogo as boolean ?? false


            if (data.birthdate && (data.birthdate as Timestamp).seconds) {
                this.birthdate = (data.birthdate as Timestamp).toDate()

            } else {
                this.birthdate = data.birthdate as Date
            }


            try {
                if (data.hasLogo) {
                    const affiliateLogoStoragePath = `company/${this.employee.company.id}/employee/${this.employee.id}/affiliates/${this.id}/logo`
                    const storageRef = storage_ref(storage, affiliateLogoStoragePath)

                    const url = await getDownloadURL(storageRef)
                    this._logo = url

                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    override toData() {
        const data: Record<string, unknown> = {}
        data.firstName = this.firstName
        data.lastName = this.lastName
        data.phone = this.phone
        data.mobile = this.mobile
        data.email = this.email
        data.companyName = this.companyName
        data.notes = this.notes
        data.hasLogo = this.hasLogo
        data.lastUpdate = this.lastUpdate

        if (this.birthdate) {
            data.birthdate = this.birthdate
        }

        return data
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private base64ToFile = async (data: any) => {
        return new Promise((resolve) => {
            fetch(data)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], "logo", {
                        // type: "application/pdf"
                    })
                    resolve(file)
                })
        })
    }

    override async set() {
        this.lastUpdate = new Date()
        await super.set()

        if (this.hasLogo) {
            const affiliateLogoStoragePath = `company/${this.employee.company.id}/employee/${this.employee.id}/affiliates/${this.id}/logo`

            const storageRef = storage_ref(storage, affiliateLogoStoragePath)
            const file = await this.base64ToFile(this._logo)
            if (file) {
                await uploadBytes(storageRef, file as File)
            }
        }
    }

    override async save() {
        this.lastUpdate = new Date()
        await super.save()

        if (this.hasLogo) {
            const affiliateLogoStoragePath = `company/${this.employee.company.id}/employee/${this.employee.id}/affiliates/${this.id}/logo`

            const storageRef = storage_ref(storage, affiliateLogoStoragePath)
            const file = await this.base64ToFile(this._logo)
            if (file) {
                await uploadBytes(storageRef, file as File)
                this.lastUpdate = new Date()
                await super.save()
                console.log("Fertig hochgeladen")
            }
        }
    }

    override async load() {
        const data = await super.load()
        try {
            if (this.hasLogo) {
                const affiliateLogoStoragePath = `company/${this.employee.company.id}/employee/${this.employee.id}/affiliates/${this.id}/logo`
                const storageRef = storage_ref(storage, affiliateLogoStoragePath)

                const url = await getDownloadURL(storageRef)
                this._logo = url
            }

        } catch (err) {
            console.log(err)
        }

        return data
    }

    get ref() {
        return doc(this.collectionRef, this.id)
    }

    get collectionRef() {
        return collection(this._employee.ref, "affiliates")
    }
}
