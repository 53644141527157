import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import { Campaign } from "@/classes/Campaign"
import { Employee } from "@/classes/Employee"

export interface CampaignsState {
    campaigns: Map<string, Campaign[]>,
    firestoreListener: {
        campaigns: Unsubscribe | null
    }
}

const module: Module<CampaignsState, unknown> = {
    state: {
        campaigns: new Map<string, Campaign[]>(),
        firestoreListener: {
            campaigns: null
        }
    },
    actions: {
        getEmployeeCampaigns({ state }, employee: Employee) {

            const createCampaign = async (employee: Employee, id: string, name: string) => {
                const campaign = new Campaign(employee, id)
                campaign.fromData({
                    name: name,
                    isDefault: true,
                    rules: [
                        {
                            productid: "standard_baufi_ad",
                            operator: ">=",
                            fieldid: "0ec2cc15-4371-46de-b3e6-ea06ad17b024",
                            value: "3",
                            uniqueId: "464470e0-2ef8-453e-98d5-fd2332559953"
                        },
                        {
                            productid: "standard_bausparen_frei",
                            operator: "assigned",
                            fieldid: "",
                            value: "",
                            uniqueId: "3fabd538-b69a-4291-9dd8-06c78299253f"
                        }
                    ]
                })
                
                await campaign.set()
                return campaign
            }

            const createDefaultCampaigns = async (employee: Employee) => {
                await createCampaign(employee, "standard_baufi", "Baufi AD, Zins >= 3 und Bausparen Frei")
            }

            return new Promise((resolve, reject) => {
                if (state.campaigns?.get(employee.id)) {
                    resolve(state.campaigns?.get(employee.id))
                    return
                }

                const campaign = new Campaign(employee)
                onSnapshot(campaign.collectionRef, async (snapshot) => {
                    const _campaigns: Campaign[] = []
                    for (const doc of snapshot.docs) {
                        const obj = new Campaign(employee, doc.id)
                        obj.fromData(doc.data())
                        _campaigns.push(obj)
                    }

                    if (_campaigns.length === 0) {
                        createDefaultCampaigns(employee)
                    }

                    state.campaigns?.set(employee.id, _campaigns)
                    resolve(state.campaigns?.get(employee.id))
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }

    }
}
export default module