import { firestore } from "@/firebase"
import { collection, CollectionReference, doc, DocumentData, DocumentReference, Timestamp } from "firebase/firestore"
import { FirestoreClass } from "./FirestoreClass"

export enum AffiliateLinkType {
    UNSET = "unset",
    Baufinanzierung = "baufinanzierung",
    Privatkredite = "privatkredite",
    Immobilien = "immobilien"
  }

export class Affiliate {
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phone: string = ""
    companyName: string = ""
    logo?: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.firstName = data.firstName as string
            this.lastName = data.lastName as string
            this.email = data.email as string
            this.phone = data.phone as string

            if(data.logo) {
                this.logo = data.logo as string
            }
            if(data.companyName) {
                this.companyName = data.companyName as string
            }
        }
        
    }
    toData() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            logo: this.logo,
            companyName: this.companyName
        }
    }
}

export class MerchantRef {
    company: string = ""
    user: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.company = data.company as string
            this.user = data.user as string
        }
    }
    toData() {
        return {
            company: this.company,
            user: this.user
        }
    }
}

export class AffiliateLinkOld extends FirestoreClass {
    active: boolean = true
    createdAt: Date = new Date()
    affiliate: Affiliate = new Affiliate()
    merchant: MerchantRef = new MerchantRef()
    type: AffiliateLinkType = AffiliateLinkType.Baufinanzierung

    constructor(id: string = "") {
        super(id)
    }

    override fromData(data: Record<string, unknown>): void {
        if (data) {
            this.active = data.active as boolean
            this.createdAt = (data.createdAt as Timestamp).toDate()
            this.affiliate = new Affiliate(data.affiliate as Record<string, unknown>)
            this.merchant = new MerchantRef(data.merchant as Record<string, unknown>)
            this.type = data.type as AffiliateLinkType
        }
    }
    override toData() {
        const data: Record<string, unknown> = {}
        data.active = this.active
        data.createdAt = Timestamp.fromDate(this.createdAt)
        data.affiliate = this.affiliate.toData()
        data.merchant = this.merchant.toData()
        data.type = this.type as string
        return data
    }

    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(firestore, "affiliateLink")
    }
}
