import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import { Affiliate } from "@/classes/Affiliate"
import { Employee } from "@/classes/Employee"

export interface AffiliatesState {
    affiliates: Map<string, Affiliate[]>,
    firestoreListener: {
        affiliates: Unsubscribe | null
    }
}

const module: Module<AffiliatesState, unknown> = {
    state: {
        affiliates: new Map<string, Affiliate[]>(),
        firestoreListener: {
            affiliates: null
        }
    },
    actions: {
        getEmployeeAffiliates({ state }, employee: Employee) {

            return new Promise((resolve, reject) => {
                if (state.affiliates?.get(employee.id)) {
                    resolve(state.affiliates?.get(employee.id))
                    return
                }

                const affiliate = new Affiliate(employee)
                onSnapshot(affiliate.collectionRef, async (snapshot) => {
                    
                    const _affiliates: Affiliate[] = []
                    for (const doc of snapshot.docs) {
                        const obj = new Affiliate(employee, doc.id)
                        await obj.fromData(doc.data())
                        _affiliates.push(obj)
                    }

                    state.affiliates?.set(employee.id, _affiliates)
                    resolve(state.affiliates?.get(employee.id))
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }

    }
}
export default module