import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "inline-block bg-white fill-black border-border border rounded-full overflow-hidden" }
const _hoisted_2 = { class: "flex justify-center items-center w-full h-full" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_item = _resolveComponent("icon-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.src)
        ? (_openBlock(), _createBlock(_component_icon_item, {
            key: 0,
            path: _ctx.mdiAccountOutline
          }, null, 8, ["path"]))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.src,
            class: "w-full h-full"
          }, null, 8, _hoisted_3))
    ])
  ]))
}