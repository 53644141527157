
import { defineComponent, computed, watch } from "vue"
import AppNavigation from "@/components/app/app-navigation.vue"
import { useStore } from "vuex"
import SnackbarItem from "./components/theme/snackbar-item.vue"
import IconItem from "./components/theme/icon-item.vue"
import { Company } from "./classes/Company"
import { mdiLoading } from "@mdi/js"

export default defineComponent({
    setup() {
        const store = useStore()
        const isLoading = computed(() => {
            return store.state.auth.isAuthenticating || store.state.isLoading || store.state.company.isLoading
        })
        const isAuthenticating = computed(() => {
            return store.state.auth.isAuthenticating
        })
        const appClassList = computed(() => {
            const list = ["bg-white"]
            switch (store.state.app.theme) {
                case "custom":
                    list.push("theme-custom")
                    break
                case "business-luchs":
                    list.push("theme-business-luchs")
                    break
                default:
                    list.push("theme-florian-thoms")
                    break
            }
            return list
        })
        const contentClassList = computed(() => {
            const list = ["transition-opacity", "w-full"]
            if (store.state.isLoading) {
                list.push("opacity-50")
            }
            return list
        })

        const showSnackbar = computed({
            get(): boolean {
                return store.state.app.snackbar.visible
            },
            set(newVal: boolean) {
                store.state.app.snackbar.visible = newVal
            }
        })
        const snackbarMessage = computed(() => {
            return store.state.app.snackbar.message
        })
        const snackbarColor = computed(() => {
            return store.state.app.snackbar.color
        })

        watch(() => store.state.company.company, (company: Company) => {
            if (company) {
                document.title = `CRM - ${company.name}`
                if (company.colors) {
                    store.state.app.theme = "custom"
                    document.documentElement.style.setProperty("--color-primary", `${company.colors.primary.r} ${company.colors.primary.g} ${company.colors.primary.b}`)
                    document.documentElement.style.setProperty("--color-primary-hover", `${company.colors.primaryHover.r} ${company.colors.primaryHover.g} ${company.colors.primaryHover.b}`)
                    document.documentElement.style.setProperty("--color-border", `${company.colors.border.r} ${company.colors.border.g} ${company.colors.border.b}`)
                    document.documentElement.style.setProperty("--color-card", `${company.colors.card.r} ${company.colors.card.g} ${company.colors.card.b}`)
                    document.documentElement.style.setProperty("--color-background", `${company.colors.background.r} ${company.colors.background.g} ${company.colors.background.b}`)
                }
            }
        }, { immediate: true })

        return {
            isLoading,
            isAuthenticating,
            appClassList,
            contentClassList,
            showSnackbar,
            snackbarMessage,
            snackbarColor,
            mdiLoading
        }
    },
    components: {
        AppNavigation,
        SnackbarItem,
        IconItem
    }
})
