import { FirestoreClass } from "./FirestoreClass"
import { firestore } from "@/firebase"
import { collection, doc } from "firebase/firestore"

export class CategoryTemplateHeadlineItemFile {
    category: string = ""
    headline: string = ""
    guid: string = ""
    name: string = ""
    title: string = ""
    declineMessage: string = ""
    declined: boolean = false


    constructor(data: Record<string, unknown> | null = null) {
        this.category = data?.category as string || ""
        this.headline = data?.headline as string || ""
        this.guid = data?.guid as string || ""
        this.name = data?.name as string || ""
        this.title = data?.title as string || ""
        this.declineMessage = data?.declineMessage as string || ""
        this.declined = data?.declined as boolean || false
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title,
            name: this.name,
            guid: this.guid,
            headline: this.headline,
            category: this.category,
            declineMessage: this.declineMessage,
            declined: this.declined
        }

        return data
    }
}

export class CategoryTemplateHeadlineItemSubQuestion {
    type: string = ""
    title: string = ""
    answer: string = ""
    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.type = data.type as string || "",
            this.title = data.title as string || "",
            this.answer = data.answer as string || ""
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            type: this.type as string || "",
            title: this.title as string || "",
            answer: this.answer as string || ""
        }
        return data
    }
}

export class CategoryTemplateHeadlineItemAnswer {
    title: string = ""
    subquestion: CategoryTemplateHeadlineItemSubQuestion = new CategoryTemplateHeadlineItemSubQuestion({})

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.title = data.title as string || "",
            this.subquestion = new CategoryTemplateHeadlineItemSubQuestion(data.subquestion as Record<string, unknown>)
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title as string || "",
            subquestion: this.subquestion.toData()
        }
        return data
    }
}

export class CategoryTemplateHeadlineItemDynTemplateField {
    default: string = ""
    field: string = ""
    title: string = ""
    type: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        this.default = data?.default as string || ""
        this.field = data?.field as string || ""
        this.title = data?.title as string || ""
        this.type = data?.type as string || ""
    }

    toData() {
        const data: Record<string, unknown> = {
            default: this.default,
            field: this.field,
            title: this.title,
            type: this.type
        }

        return data
    }
}
export class CategoryTemplateHeadlineItemDynTemplate {
    fields: CategoryTemplateHeadlineItemDynTemplateField[]
    filename: string = ""
    normalmode: boolean = true
    partnermode: boolean = false
    template: string = ""
    template_solo: string = ""
    template_partner: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        this.filename = data?.filename as string || ""
        this.normalmode = data?.normalmode as boolean || true
        this.partnermode = data?.partnermode as boolean || false
        this.template = data?.template as string || ""
        this.template_solo = data?.template_solo as string || ""
        this.template_partner = data?.template_partner as string || ""

        this.fields = []
        if (data?.fields) {
            for (const field of data?.fields as (Record<string, unknown> | null)[]) {
                if (field) {
                    const tempAnswer = new CategoryTemplateHeadlineItemDynTemplateField(field)
                    this.fields.push(tempAnswer)
                }
            }
        }
    }


    toData() {
        const data: Record<string, unknown> = {
            normalmode: this.normalmode,
            partnermode: this.partnermode,
            template: this.template,
            template_solo: this.template_solo,
            template_partner: this.template_partner
        }

        const fields: Record<string, unknown>[] = []
        if (this.fields) {
            for (const field of this.fields) {
                fields.push(field.toData())
            }
        }
        data.fields = fields

        return data
    }


}

export enum CategoryTemplateQuestType {
    documentUpload = "documentUpload",
    question = "question",
    schufa = "schufa"
}

export class CategoryTemplateHeadlineItem {
    order: number = -1
    title: string = ""
    questType: CategoryTemplateQuestType = CategoryTemplateQuestType.documentUpload
    toComplete: boolean = false
    rememberDaily: boolean = true
    done: boolean = false
    Files: CategoryTemplateHeadlineItemFile[]
    answer: string = ""
    type: string = ""
    answers: CategoryTemplateHeadlineItemAnswer[]
    dynTemplate: CategoryTemplateHeadlineItemDynTemplate

    schufaAnswerOne: boolean = true
    schufaAnswerTwo: boolean = true

    constructor(data: Record<string, unknown> | null = null) {
        this.title = data?.title as string || ""
        this.order = data?.order as number || -1
        this.questType = data?.questType as CategoryTemplateQuestType || CategoryTemplateQuestType.documentUpload
        this.toComplete = data?.toComplete as boolean || false
        this.rememberDaily = data?.rememberDaily as boolean || false
        this.done = data?.done as boolean || false
        this.answer = data?.answer as string || ""
        this.type = data?.type as string || ""
        this.dynTemplate = data?.dynTemplate as CategoryTemplateHeadlineItemDynTemplate || new CategoryTemplateHeadlineItemDynTemplate()
        
        this.schufaAnswerOne = data?.schufaAnswerOne as boolean
        this.schufaAnswerTwo = data?.schufaAnswerTwo as boolean


        this.Files = []
        if(data?.Files){
            for(const file of data?.Files as (Record<string, unknown> | null)[]){
                if (file) {
                    const tempCategoryHeadlineItemFile = new CategoryTemplateHeadlineItemFile(file)
                    this.Files.push(tempCategoryHeadlineItemFile)
                }
            }
        }

        this.answers = []
        if(data?.answers){
            for(const answer of data?.answers as (Record<string, unknown> | null)[]){
                if (answer) {
                    const tempAnswer = new CategoryTemplateHeadlineItemAnswer(answer)
                    this.answers.push(tempAnswer)
                }
            }
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title as string || "",
            order: this.order as number || -1,
            questType: this.questType as string || "",
            toComplete: this.toComplete as boolean || false,
            rememberDaily: this.rememberDaily,
            done: this.done as boolean || false,
            answer: this.answer as string || "",
            type: this.type as string || "",
            dynTemplate: this.dynTemplate as CategoryTemplateHeadlineItemDynTemplate || new CategoryTemplateHeadlineItemDynTemplate(),
            schufaAnswerOne: this.schufaAnswerOne as boolean || false,
            schufaAnswerTwo: this.schufaAnswerTwo as boolean || false

            
        }

        const Files: Record<string, unknown>[] = []
        if(this.Files){
            for(const file of this.Files){
                Files.push(file.toData())
            }
        }
        data.Files = Files

        const answers: Record<string, unknown>[] = []
        if(this.answers){
            for(const answer of this.answers){
                answers.push(answer.toData())
            }
        }
        data.answers = answers

        return data
    }
}
export class CategoryTemplateHeadline {
    title: string = ""
    items: CategoryTemplateHeadlineItem[] = []
    order: number = 1

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            if(data.title){
                this.title = data.title as string
            }
            if(data.order){
                this.order = data.order as number
            }
        }

        this.items = []
        if(data?.items){
            for(const item of data?.items as (Record<string, unknown> | null)[]){
                if (item) {
                    const tempCategoryHeadlineItem = new CategoryTemplateHeadlineItem(item)
                    this.items.push(tempCategoryHeadlineItem)
                }
            }
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title as string || "",
            order: this.order as number || -1
        }

        const items: Record<string, unknown>[] = []
        if(this.items){
            for(const item of this.items){
                items.push(item.toData())
            }
        }
        data.items = items

        return data
    }
}
export class CategoryTemplate extends FirestoreClass {
    categoryId: number = -1
    headlines: CategoryTemplateHeadline[] = []
    title: string = ""
    order: number = 1

    constructor(id: string = "") {
        super(id)
    }

    fromData(data: Record<string, unknown>): void {
        this.categoryId = data.categoryId as number
        this.title = data.title as string
        this.order = data.order as number

        if (data.headlines) {
            this.headlines = []
            for (const headline of data.headlines as (Record<string, unknown> | null)[]) {
                if (headline) {
                    const tempHeadline = new CategoryTemplateHeadline(headline)
                    this.headlines.push(tempHeadline)
                }
            }
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            categoryId: this.categoryId as number || -1,
            title: this.title as string || "",
            order: this.order as number || -1
        }

        data.headlines = []
        const headlines: Record<string, unknown>[] = []
        if(this.headlines){
            for(const headline of this.headlines){
                headlines.push(headline.toData())
            }
        }
        data.headlines = headlines
        return data
    }

    get ref() {
        return doc(this.collectionRef, this._id)
    }
    get collectionRef() {
        return collection(firestore, "categoryTemplate")
    }
}


    

