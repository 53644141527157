
import { defineComponent } from "vue"
import logoBusinessLuchs from "@/assets/logos/logo-business-luchs.svg"
import logoBusinessLuchsText from "@/assets/logos/logo-business-luchs-text.svg"
import { computed } from "@vue/reactivity"
import { useStore } from "vuex"

export default defineComponent({
    props: {
        type: {
            type: String,
            default: "logo"
        }
    },
    setup(props) {
        const store = useStore()
        const logoSrc = computed(() => {
            if (store.state.company.logoSrc) {
                return store.state.company.logoSrc
            } else {
                switch(store.state.app.theme) {
                    case "business-luchs":
                        if (props.type == "text") {
                            return logoBusinessLuchsText
                        } else {
                            return logoBusinessLuchs
                        }
                    default:
                        if (props.type == "text") {
                            return logoBusinessLuchsText
                        } else {
                            return logoBusinessLuchs
                        }
                }
            }
        })
        return {
            logoSrc
        }
    }
})
