import { Company } from "@/classes/Company"
import { PublicUser } from "@/classes/PublicUser"
import { Module } from "vuex"
import store from ".."

export interface FinancerStore {
    loading: boolean
}

const module: Module<FinancerStore, unknown> = {
    state: {
        loading: false
    },
    actions: {
        getFinancersForCompany({}, company: Company) {
            return new Promise(async (resolve) => {
                const financiers: PublicUser[] = []
                for (const [userID] of Object.entries(company.activeEmployees)) {
        
                    const publicUser: PublicUser = await store.dispatch("getUserInfo", userID)
                    if (publicUser) {
                        financiers.push(publicUser)
                    }
                    
                }
                resolve(financiers)
            })
        }
    }
}
export default module