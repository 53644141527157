import { Company } from "./Company"
import { FirestoreClass } from "./FirestoreClass"
import { collection, doc, Timestamp } from "firebase/firestore"
import { MailTemplate } from "./MailTemplate"
import { CategoryTemplate } from "@/classes/CategoryTemplate"
import { Affiliate } from "./AffiliateLinkOld"
import { uuid } from "vue-uuid"

export class Address {
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    room: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.street = data.street as string || ""
            this.number = data.number as string || ""
            this.postcode = data.postcode as string || ""
            this.location = data.location as string || ""
            this.room = data.room as string || ""
        }
    }
    toData() {
        return {
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            room: this.room

        }
    }
}
export interface CustomerMailTemplate {
    user: string
    type: string
    template: string
}

export enum CustomerFileCategory {
    BuyersCertificate,
    ObjectFiles,
    CreditWorthinessFiles,
    PayoutDocumentFiles,
    Other,
    ContractDocuments
}

export interface CustomerFile {
    internalFileName: string,
    fileName: string
    category: CustomerFileCategory,
    created: Date,
    showInCP: boolean
}
export enum CustomerSource {
    Standard = 0,
    Affiliate = 1
}

export enum CustomerStatus {
    Offen,
    Terminiert,
    Wiedervorlage,
    NichtInteressiert,
    Bestandskunde
}

export class CustomerProduct {
    uniqueId: string
    productId: string = ""
    values: Record<string, string> = {}

    constructor(data: Record<string, unknown> | null = null) {
        this.productId = data?.productId as string || ""
        this.uniqueId = data?.uniqueId as string ?? uuid.v4()
        this.values = data?.values as Record<string, string> || {}

    }

    toData() {
        const data: Record<string, unknown> = {
            productId: this.productId as string,
            uniqueId: this.uniqueId as string,
            values: this.values as Record<string, string>
        }
        return data
    }
}
export class Customer extends FirestoreClass {
    private _company: Company
    gender: string = ""
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phone: string = ""
    mobile: string = ""
    note: string = ""
    birthday: Date | null = null
    sendBirthdayWishes: boolean = true
    mailTemplates: CustomerMailTemplate[] | null = null
    owner: string = ""
    address: Address = new Address()
    companyName: string = ""
    salutation: string = ""
    customerPortalActive: boolean = false
    payoutActive: boolean = false
    hasapartner: boolean = false
    partnerSalutation: string = ""
    partnerFirstName: string = ""
    partnerLastName: string = ""
    partnerEmail: string = ""
    partnerBirthday: Date | null = null
    files: CustomerFile[] = []
    source: CustomerSource = CustomerSource.Standard
    affiliate: Affiliate = new Affiliate()
    sendDailyReminder: boolean = false
    dailyReminderCCMe: boolean = false
    state: number = CustomerStatus.Offen
    userid: string = ""
    products: CustomerProduct[] = []
    affiliateId: string = ""
    created: Date = new Date()
    lastOpened: Date = new Date()
    thinkImmoId: string = ""
    thinkImmoAccessToken: string = ""
    
    constructor(company: Company, owner: string, categoryTemplates: CategoryTemplate[], id: string) {
        super(id)
        this._company = company
        this.owner = owner
        this.created = new Date()
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`.trim()
    }

    addMailTemplate(template: MailTemplate) {
        if (!this.mailTemplates) {
            this.mailTemplates = []
        }
        const index = this.mailTemplates.findIndex((a) => {
            return a.user == template.publicEmployee.id && a.template == template.id
        })
        if (index == -1) {
            this.mailTemplates.push({
                user: template.publicEmployee.id,
                type: template.type,
                template: template.id
            })
        }
    }
    deleteMailTempalte(template: MailTemplate) {
        if (!this.mailTemplates) {
            return
        }
        const index = this.mailTemplates.findIndex((a) => {
            return a.user == template.publicEmployee.id && a.template == template.id
        })
        if (index != -1) {
            this.mailTemplates.splice(index, 1)
        }
    }

    fromData(data: Record<string, unknown>): void {
        this.gender = data.gender as string
        this.firstName = data.firstName as string
        this.lastName = data.lastName as string
        this.email = data.email as string
        this.phone = data.phone as string
        this.mobile = data.mobile as string
        this.note = data.note as string || ""
        this.owner = data.owner as string || ""
        this.companyName = data.companyName as string || ""
        this.salutation = data.salutation as string || ""
        this.userid = data.userid as string || ""

        this.payoutActive = data.payoutActive as boolean || false
        this.hasapartner = data.hasapartner as boolean || false
        this.partnerSalutation = data.partnerSalutation as string || ""
        this.partnerFirstName = data.partnerFirstName as string || ""
        this.partnerLastName = data.partnerLastName as string || ""
        this.partnerEmail = data.partnerEmail as string || ""

        this.source = data.source as CustomerSource || CustomerSource.Standard
        this.files = data.files as CustomerFile[] || []
        this.mailTemplates = data.mailTemplates as CustomerMailTemplate[] || null

        this.state = data.state as CustomerStatus || CustomerStatus.Offen
        this.affiliateId = data.affiliateId as string || ""
        this.thinkImmoId = data.thinkImmoId as string || ""
        this.thinkImmoAccessToken = data.thinkImmoAccessToken as string || ""

        if (data.customerPortalActive) {
            this.customerPortalActive = data.customerPortalActive as boolean
        }

        if (data.affiliate) {
            this.affiliate = new Affiliate(data.affiliate as Record<string, unknown>)
        }

        if (data.sendDailyReminder === undefined) {
            this.sendDailyReminder = this.customerPortalActive
        } else {
            this.sendDailyReminder = data.sendDailyReminder as boolean
        }

        if (data.dailyReminderCCMe === undefined) {
            this.dailyReminderCCMe = this.customerPortalActive
        } else {
            this.dailyReminderCCMe = data.dailyReminderCCMe as boolean
        }

        if (data.address) {
            this.address = new Address(data.address as Record<string, unknown>)
        }

        if (data.birthday) {
            this.birthday = (data.birthday as Timestamp).toDate()
        } else {
            this.birthday = null
        }

        if (data.sendBirthdayWishes == false) {
            this.sendBirthdayWishes = false
        } else {
            data.sendBirthdayWishes = true
        }

        if (data.partnerBirthday) {
            this.partnerBirthday = (data.partnerBirthday as Timestamp).toDate()
        } else {
            this.partnerBirthday = null
        }

        if (data.created) {
            this.created = (data.created as Timestamp).toDate()
        } else {
            this.created = new Date(2000, 0, 1)
        }
        if (data.lastOpened) {
            this.lastOpened = (data.lastOpened as Timestamp).toDate()
        } else {
            this.lastOpened = new Date(2000, 0, 1)
        }


        if (data.products) {
            this.products = []
            for (const product of data.products as (Record<string, unknown> | null)[]) {
                if (product) {
                    const tempCategory = new CustomerProduct(product)
                    this.products.push(tempCategory)
                }
            }
        }

    }

    toData() {
        const data: Record<string, unknown> = {
            firstName: this.firstName,
            lastName: this.lastName,
            gender: this.gender,
            phone: this.phone,
            mobile: this.mobile,
            email: this.email,
            note: this.note,
            birthday: this.birthday,
            partnerBirthday: this.partnerBirthday,
            sendBirthdayWishes: this.sendBirthdayWishes,
            owner: this.owner,
            address: this.address.toData(),
            companyName: this.companyName,
            salutation: this.salutation,
            customerPortalActive: this.customerPortalActive,
            payoutActive: this.payoutActive,
            hasapartner: this.hasapartner,
            partnerSalutation: this.partnerSalutation,
            partnerFirstName: this.partnerFirstName,
            partnerLastName: this.partnerLastName,
            partnerEmail: this.partnerEmail,
            files: this.files,
            source: this.source,
            affiliate: this.affiliate.toData(),
            sendDailyReminder: this.sendDailyReminder,
            dailyReminderCCMe: this.dailyReminderCCMe,
            state: this.state,
            userid: this.userid,
            affiliateId: this.affiliateId,
            created: this.created,
            lastOpened: this.lastOpened,
            thinkImmoId: this.thinkImmoId,
            thinkImmoAccessToken: this.thinkImmoAccessToken
        }

        if (this.birthday) {
            data._birthday = {
                date: this.birthday.getDate(),
                month: (this.birthday.getMonth() + 1)
            }
        } else {
            data._birthday = null
        }
        
        if (this.partnerBirthday) {
            data._partnerBirthday = {
                date: this.partnerBirthday.getDate(),
                month: (this.partnerBirthday.getMonth() + 1)
            }
        } else {
            data._partnerBirthday = null
        }

        if (this.mailTemplates) {
            data.mailTemplates = this.mailTemplates
        }
        data._searchIndex = [
            this.firstName.toUpperCase(),
            this.lastName.toUpperCase(),
            this.email.toUpperCase(),
            this.phone.toUpperCase()
        ]

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const products: any[] = []
        for (const product of this.products) {
            products.push(product.toData())
        }
        data.products = products

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const productFilters: any = {}
        for (const product of this.products) {
            productFilters[product.productId] = {}

            for (const value of Object.entries(product.values)) {
                productFilters[product.productId][value[0]] = value[1]
            }
        }
        data.productFilters = productFilters

        return data
    }

    get ref() {
        return doc(this.collectionRef, this._id)
    }
    get collectionRef() {
        return collection(this._company.ref, "customer")
    }

    get company() {
        return this._company
    }
}