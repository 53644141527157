import { collection, doc, getDocs, onSnapshot, query, Unsubscribe, where } from "firebase/firestore"
import store from ".."

import { Module } from "vuex"
import { Employee } from "@/classes/Employee"

export interface EmployeeState {
    employees: Employee[],
    employee: Employee | null,
    firestoreListener: {
        employees: Unsubscribe | null,
        employee: Unsubscribe | null
    }
}

const module: Module<EmployeeState, unknown> = {
    state: {
        employees: [],
        employee: null,
        firestoreListener: {
            employees: null,
            employee: null
        }
    },
    actions: {
        getEmployee({ state }, id: string) {
            return new Promise(async (resolve, reject) => {
                for (const employee of state.employees) {
                    if (employee.id == id) {
                        resolve(employee)
                        return
                    }
                }

                const company = store.state.company!.company!
                const _i = new Employee(company, id)
                if (await _i.load()) {
                    state.employees.push(_i)
                    resolve(_i)
                    return
                }
                reject(new Error("not_found"))
            })
        },
        attachEmployees({ state }) {
            return new Promise((resolve, reject) => {
                if (state.firestoreListener.employees) {
                    resolve(state.employees)
                    return
                }
                const col = collection(store.state.company!.company!.ref, "employee")
                // const sub: string = store.state.auth?.profile?.sub??""
                // const ref = query(col, where("active", "==", true))
                
                state.firestoreListener.employees = onSnapshot(col, (snapshot) => {
                    state.employees = []
                    for (const doc of snapshot.docs) {
                        const obj = new Employee(store.state.company!.company!, doc.id)
                        obj.fromData(doc.data())
                        state.employees.push(obj)
                    }
                    
                    resolve(state.employees)
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        },
        attachEmployee({ state }) {
            return new Promise((resolve, reject) => {
                if (state.firestoreListener.employee) {
                    resolve(state.employee)
                    return
                }
                const col = collection(store.state.company!.company!.ref, "employee")
                const _doc = doc(col, store.state.auth?.profile?.sub)
                
                state.firestoreListener.employee = onSnapshot(col, (snapshot) => {
                    state.employees = []
                    for (const doc of snapshot.docs) {
                        const obj = new Employee(store.state.company!.company!, doc.id)
                        obj.fromData(doc.data())
                        state.employees.push(obj)
                    }
                    
                    resolve(state.employees)
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        },
        searchEmployee({ state }, search: string) {
            return new Promise(async (resolve, reject) => {
                if (!search) {
                    reject(new Error("no_search_term"))
                    return
                }

                const sub: string = store.state.auth?.profile?.sub ?? ""

                const company = store.state.company!.company!
                const searchTerms = search.toUpperCase().split(" ")
                const collectionRef = collection(company.ref, "employee")
                const queryRef = query(
                    collectionRef,
                    where("owner", "==", sub),
                    where("dispatcher", "==", store.state.auth!.profile!.company),
                    where("_searchIndex", "array-contains-any", searchTerms)
                )
                const searchResults = await getDocs(queryRef)
                const employee: Employee[] = []
                for (const result of searchResults.docs) {
                    const _i = new Employee(company, result.id)
                    _i.fromData(result.data())
                    const employeeIndex = state.employees.findIndex((a) => a.id == _i.id)
                    if (employeeIndex == -1) {
                        state.employees.push(_i)
                    }
                    employee.push(_i)
                }
                resolve(employee)
            })
        }

    }
}
export default module