import { mdiAccount, mdiAccountMultiple, mdiAccountStar, mdiBank, mdiCalendarClock, mdiChartBar, mdiChartTimelineVariant, mdiChartTimelineVariantShimmer, mdiDomain, mdiFrequentlyAskedQuestions, mdiTextBoxMultipleOutline, mdiVectorLink, mdiViewDashboard } from "@mdi/js"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../views/home-view.vue")
    },
    {
        path: "/user-profile",
        name: "user-profile",
        component: () => import(/* webpackChunkName: "user-profile" */ "../views/user-profile-view.vue"),
        meta: {
            title: "Profil",
            showInPPM: true,
            PPMOrder: 1,
            icon: mdiAccount
        }
    },
    {
        path: "/admin/company-profile/:companyid/user-profile/:userid",
        name: "admin-user-profile",
        component: () => import(/* webpackChunkName: "admin-user-profile" */ "../views/user-profile-view.vue")
    },
    {
        path: "/company-profile",
        name: "company-profile",
        component: () => import(/* webpackChunkName: "company-profile" */ "../views/company-profile-view.vue"),
        meta: {
            title: "Unternehmenseinstellungen",
            showInPPM: true,
            PPMOrder: 2,
            icon: mdiDomain,
            onlyCompanyAdmin: true
        }
    },
    {
        path: "/admin/company-profile/:companyid",
        name: "admin-company-profile",
        component: () => import(/* webpackChunkName: "admin-company-profile" */ "../views/company-profile-view.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login-view.vue")
    },
    {
        path: "/logoff",
        name: "logoff",
        component: () => import(/* webpackChunkName: "logoff" */ "../views/logoff-view.vue")
    },
    {
        path: "/requests",
        name: "requests",
        component: () => import(/* webpackChunkName: "requests" */ "../views/requests-view.vue"),
        meta: {
            title: "Offene Anfragen",
            showInNavBar: true,
            navBarOrder: 1,
            icon: mdiViewDashboard
        }
    },
    {
        path: "/request/:objectID",
        name: "request",
        component: () => import(/* webpackChunkName: "request" */ "../views/request-view.vue")
    },
    {
        path: "/object/:objectID",
        name: "object",
        component: () => import(/* webpackChunkName: "object" */ "../views/object-view.vue")
    },
    {
        path: "/customers",
        name: "customer-list",
        component: () => import(/* webpackChunkName: "customer-list" */ "../views/customer-list-view.vue"),
        meta: {
            title: "Kunden",
            showInNavBar: true,
            navBarOrder: 2,
            icon: mdiAccountMultiple
        }
    },
    {
        path: "/company/:companyid/customer/:customerid",
        name: "customerCustomCompany",
        component: () => import(/* webpackChunkName: "customerCustomCompany" */ "../views/customer-view.vue")
    },
    {
        path: "/customer/:customerid",
        name: "customer",
        component: () => import(/* webpackChunkName: "customer" */ "../views/customer-view.vue")
    },
    {
        path: "/customer/:customerid/follow-up/:followUpID",
        name: "follow-up",
        component: () => import(/* webpackChunkName: "follow-up" */ "../views/follow-up-view.vue")
    },
    {
        path: "/customer/:customerid/selfdisclosure/",
        name: "selfdisclosure",
        component: () => import(/* webpackChunkName: "selfdisclosure" */ "../views/selfdisclosure2-view.vue")
    },
    {
        path: "/customer/:customerid/selfdisclosure2/",
        name: "selfdisclosure2",
        component: () => import(/* webpackChunkName: "selfdisclosure2" */ "../views/selfdisclosure2-view.vue")
    },

    {
        path: "/customerPortal/:customerid",
        name: "customerPortal",
        component: () => import(/* webpackChunkName: "customerPortal" */ "../views/customer-portal-view.vue")
    },
    {
        path: "/calendar",
        name: "calendar",
        component: () => import(/* webpackChunkName: "calendar" */ "../views/calendar-view.vue"),
        meta: {
            title: "Kalender",
            showInNavBar: true,
            navBarOrder: 3,
            icon: mdiCalendarClock
        }
    },
    {
        path: "/follow-ups",
        name: "follow-ups",
        component: () => import(/* webpackChunkName: "follow-ups" */ "../views/follow-ups-view.vue"),
        meta: {
            title: "Wiedervorlagen",
            showInNavBar: true,
            navBarOrder: 4,
            icon: mdiTextBoxMultipleOutline

        }
    },
    {
        path: "/affiliate-links-old",
        name: "affiliate-links-old",
        component: () => import(/* webpackChunkName: "affiliate-links-old" */ "../views/affiliate-links-old-view.vue"),
        meta: {
            title: "Affiliate Links",
            showInNavBar: false,
            navBarOrder: 99,
            icon: mdiVectorLink
        }
    },
    {
        path: "/affiliate-link-old/:affiliateLinkId",
        name: "affiliate-link-old",
        component: () => import(/* webpackChunkName: "affiliate-link-old" */ "../views/affiliate-link-old-view.vue")
    },
    {
        path: "/products",
        name: "products",
        component: () => import(/* webpackChunkName: "products" */ "../views/products-view.vue"),
        meta: {
            title: "Produkte",
            showInPPM: true,
            PPMOrder: 3,
            icon: mdiBank
        }
    },
    {
        path: "/campaigns",
        name: "campaigns",
        component: () => import(/* webpackChunkName: "campaigns" */ "../views/campaigns-view.vue"),
        meta: {
            title: "Kampagnen",
            showInNavBar: true,
            navBarOrder: 6,
            icon: mdiChartBar
        }
    },
    {
        path: "/company/:companyid/employee/:employeeid/campaigns/:campaignid",
        name: "campaign",
        component: () => import(/* webpackChunkName: "campaign" */ "../views/campaign-view.vue")
    },
    {
        path: "/affiliate-routes",
        name: "affiliate-routes",
        component: () => import(/* webpackChunkName: "affiliate-routes" */ "../views/affiliate-routes-view.vue"),
        meta: {
            title: "Affiliate Strecken",
            showInPPM: true,
            PPMOrder: 5,
            icon: mdiChartTimelineVariant
        }
    },
    {
        path: "/affiliate-links",
        name: "affiliate-links",
        component: () => import(/* webpackChunkName: "affiliate-links" */ "../views/affiliate-links-view.vue"),
        meta: {
            title: "Affiliate Links",
            showInNavBar: true,
            navBarOrder: 5,
            icon: mdiChartTimelineVariantShimmer,
            onlyBetaTester: false
            
        }
    },
    {
        path: "/company/:companyid/employee/:employeeid/affiliate-route/:affiliateRouteId",
        name: "affiliate-route",
        component: () => import(/* webpackChunkName: "affiliate-route" */ "../views/affiliate-route-view.vue")
    },
    {
        path: "/company/:companyid/employee/:employeeid/affiliate-link/:affiliateLinkId",
        name: "affiliate-link",
        component: () => import(/* webpackChunkName: "affiliate-link" */ "../views/affiliate-link-view.vue")
    },
    {
        path: "/affiliates",
        name: "affiliates",
        component: () => import(/* webpackChunkName: "affiliates" */ "../views/affiliates-view.vue"),
        meta: {
            title: "Zuträger",
            showInPPM: true,
            PPMOrder: 4,
            icon: mdiAccountStar
        }
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import(/* webpackChunkName: "faq" */ "../views/faq-view.vue"),
        meta: {
            title: "FAQ",
            showInPPM: true,
            PPMOrder: 99,
            icon: mdiFrequentlyAskedQuestions
        }
    },
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "error" */ "../views/error-view.vue")
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: () => {
        return { top: 0 }
    }
})

export default router
